import { Link } from "gatsby"
import React, { useState } from 'react';
import { graphql } from 'gatsby';
import Img from "gatsby-image"
import XMRModal, { XMRButton } from "../components/xmrBtn"
import Layout from '../components/layout';
import Download from '../components/download';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SEO from '../components/seo'

const sliderStyle = {
    /* overflow: hidden; */
    /* display: block; */
    margin: `9%`,
    marginTop:`0`,
    marginBottom: `10%`,
    border: `1px solid`
};


const PostTemplate = ({ data }) => {

    const [showXMR, setShowXMR] = useState(false);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1

    };


    const { markdownRemark } = data;

    const { frontmatter, html, excerpt } = markdownRemark;

    const url = data.site.siteMetadata.url + markdownRemark.fields.slug;

    let img = null;
    let imgW = 0;
    let imgH = 0;

    const tags = (frontmatter.tags) ? frontmatter.tags : "Book Writing Poet";

    if (frontmatter.preview1) {
        img = frontmatter.preview1.childImageSharp.fluid.src;
        imgW = frontmatter.preview1.childImageSharp.fluid.src.presentationWidth;
        imgH = frontmatter.preview1.childImageSharp.fluid.src.presentationHeight;
    }

    const schema = {
        "@context": "https://schema.org",
        "@graph":[
            {
                "@type": "Product",
                "name": "Book | "+frontmatter.title,
                "keywords": tags.split(" "),
                "url": url,
                "description": excerpt,
                "image":img,
                "offers":{
                    "priceValidUnitil": "2035-05-22",
                    "url":url,
                    "@type":"Offer",
                    "availability":"https://schema.org/InStock",
                    "price":"0.00",
                    "brand":"clacdael",
                    "sku":"00000000000000",
                    "priceCurrency":"GBP"
                },
            },
            {
                "@type": "WebSite",
                "about": "Poetry Books and other, from the UK poet Christian Lacdael",
                "copyrightHolder": {
                    "@type": "Person",
                    "name": "Christian Lacdael"
                },
                "inLanguage": "en-gb",
                "isFamilyFriendly": true,
                "url": "https://www.poetry.me.uk"
            },
            {
                "@type": "BreadcrumbList",
                "description": "Breadcrumbs list",
                "itemListElement": [
                    {
                        "@type": "ListItem",
                        "item": "https://www.poetry.me.uk",
                        "name": "MainPage",
                        "position": 1
                    },
                    {
                        "@type": "ListItem",
                        "item": `https://www.poetry.me.uk/books/`,
                        "name": "Books",
                        "position": 2
                    },
                    {
                        "@type": "ListItem",
                            "item": `https://www.poetry.me.uk/books/${ frontmatter.title }`,
                            "name": frontmatter.title,
                            "position": 3
                    }
                ],
                "name": "Breadcrumbs"
            },
        ]
    };

    if (img) schema["image"] = {"@type":"ImageObject","url":(url+img),"width":imgW,"height":imgH};

    return (
        <Layout>
        <SEO title={"Book | "+frontmatter.title} description={excerpt} tags={tags} what="book" image={img} date={frontmatter.date} schemaMarkup={schema} />
        { showXMR && <XMRModal show={setShowXMR} /> }
        <section style={{margin:0.5+`em`,overflow:`hidden`}}>
        <ul className="breadcrumb">
        <li><Link to="/">Home</Link></li>
        <li><Link to="/books">Books</Link></li>
        <li>{frontmatter.title}</li>
        </ul>
        <h1 style={{textAlign:`center`}}>{frontmatter.title}</h1>
        <div style={{float:`left`,width:`15em`,textAlign:`center`}}>
        { frontmatter.preview3 ? <Slider style={sliderStyle} {...settings} >
            { frontmatter.preview1 && <div><Img key="1" fluid={frontmatter.preview1.childImageSharp.fluid} alt={frontmatter.title} /></div> }
            { frontmatter.preview2 && <div><Img key="2" fluid={frontmatter.preview2.childImageSharp.fluid} alt={frontmatter.title} /></div> }
            { frontmatter.preview3 && <div><Img key="3" fluid={frontmatter.preview3.childImageSharp.fluid} alt={frontmatter.title} /></div> }
            </Slider> :  <div style={{padding:`1em`}} ><Img key="1" fluid={frontmatter.preview1.childImageSharp.fluid} alt={frontmatter.title} /></div> }
        { frontmatter.download && <Download style={{width:`82%`,margin:`9% 0% 4% 9%`,display:`block`}} link={ frontmatter.download  } /> }
        <XMRButton show={setShowXMR} />
        </div>
        <div dangerouslySetInnerHTML={{ __html: html }} />
        <em>{frontmatter.date}</em>
        </section>
        </Layout>
    );
};

export default PostTemplate;



export const pageQuery = graphql`
  query($slug: String!) {
    site {
        siteMetadata {
            url
        }
    },
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt(pruneLength: 250)
      fields{
        slug
      },
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        tags
        download
        preview1 {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                    src
                    presentationHeight
                    presentationWidth
                }
            }
        }
        preview2 {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        preview3 {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }

        }

      }
    }
}`;
