import { Link } from "gatsby"
import React from "react"

const Download = ({ link,style}) => (
   <Link style={style}  to={link}>
        <button style={{width:`100%`,height:`1.8em`}} type='button'>Free Download</button>
    </Link>
)

export default Download
