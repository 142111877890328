import React, { useState } from 'react';
import XMR from "../images/xmr.svg"

const XMRModal = ( props ) => {

        const ADDRESS = "4AC99QFmCipcMnEwLNuRcB83DqZ3Y7AApEcvsocegkUT6XF8W3JyxiQVxw7acoiSBhE8NYUi5ppMihFoL7RnDaW8AHdeXx4";

        const [copied, setCopied] = useState(false);

        const copyTextToClipboard = async (text) => {
            try{
                if ('clipboard' in navigator) {
                    await navigator.clipboard.writeText(text);
                    setCopied(true);
                } else {
                    return document.execCommand('copy', true, text);
                }
            } catch(e){
                console.log(e);
            }
        }

        return (

            <div id="modal" onClick={ e => props.show(false) }>
            <div id="modal-inner" style={{textAlign:`center`}} onClick={ e => {
         e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

            } }>
                    <span style={{float:`right`,cursor:`pointer`}} onClick={ e => props.show(false) }>&times;</span>
                    <img id="qr" style={{width:`15em`,margin:`auto`,display:`block`}} src="/Monero.png" />
                    <span id="public" style={{fontSize:`1.1vw`,cursor:`grab`}} onClick={ (e) => {
                        copyTextToClipboard( ADDRESS );
                        }}>{ADDRESS} &#128203;{ copied && ` COPIED` }</span>
                </div>
            </div>

        );

    };

export const XMRButton = ( props ) => <button type="button" onClick={ e => props.show(true) }><svg style={{height:"1.4em",verticalAlign:"middle"}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3756.09 3756.49"><title>monero</title><path d="M4128,2249.81C4128,3287,3287.26,4127.86,2250,4127.86S372,3287,372,2249.81,1212.76,371.75,2250,371.75,4128,1212.54,4128,2249.81Z" transform="translate(-371.96 -371.75)" style={{fill:"#fff"}}/><path d="M2250,371.75c-1036.89,0-1879.12,842.06-1877.8,1878,0.26,207.26,33.31,406.63,95.34,593.12h561.88V1263L2250,2483.57,3470.52,1263v1579.9h562c62.12-186.48,95-385.85,95.37-593.12C4129.66,1212.76,3287,372,2250,372Z" transform="translate(-371.96 -371.75)" style={{fill:"#f26822"}}/><path d="M1969.3,2764.17l-532.67-532.7v994.14H1029.38l-384.29.07c329.63,540.8,925.35,902.56,1604.91,902.56S3525.31,3766.4,3855,3225.6H3063.25V2231.47l-532.7,532.7-280.61,280.61-280.62-280.61h0Z" transform="translate(-371.96 -371.75)" style={{fill:"#4d4d4d"}}/></svg> Buy me a tea </button>

export default XMRModal

